import React from 'react'
import { SanityPress } from '@utils/types'
import { graphql } from 'gatsby'
import SEO from '@components/seo'
import loadable from '@loadable/component'
const PressGrid = loadable(() => import('@components/pressGrid'))

type Props = {
  data: {
    sanityPress: SanityPress
  }
  location: Location
}

const PressPage = ({ data: { sanityPress }, location }: Props) => {
  const metadata = {
    location: location.href,
    ...sanityPress?.seo,
  }

  return (
    <>
      <SEO {...metadata} />
      <PressGrid
        articles={sanityPress?.articles}
        title={sanityPress?.title || 'Press'}
      />
    </>
  )
}

export default PressPage

export const pageQuery = graphql`
  query {
    sanityPress(_id: { in: ["drafts.pressPage", "pressPage"] }) {
      seo {
        description
        keywords
        image
        title
        twitterCard
        twitterCreator
        twitterSite
      }
      slug {
        current
      }
      title
      articles {
        image {
          alt
          asset {
            gatsbyImageData(
              placeholder: BLURRED
              height: 700
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        slug {
          current
        }
        title
        date
      }
    }
  }
`
